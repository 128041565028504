@use "src/style/variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.popup_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 416px;

  & > p {

  }

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .buttons_container {
    align-self: flex-end;
    display: flex;
    column-gap: 16px;

    .cancel_button {
      background-color: white;
    }
  }
}

.popup{
  width: fit-content;
}
