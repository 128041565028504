@use "/src/style/variables";

.container {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 5;

  .bars {
    color: variables.$white;
    cursor: pointer;
  }

  .navigation {
    position: absolute;
    top: 36px;
    right: -16px;
    background-color: variables.$white;
    display: none;
    text-decoration: none;
    padding: 24px 16px 27px;
    border-bottom-left-radius: 8px;
    min-width: 258px;

    &.active {
      display: flex;
      flex-direction: column;
      row-gap: 22px;
    }

    li {
      list-style: none;
    }

    .navigation_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 30px;

      > a {
        display: flex;
        align-items: center;
        column-gap: 12.6px;
        color: variables.$sea-80;
        white-space: nowrap;
        @include variables.text-14-medium;
        text-decoration: none;

        &.active {
          & > div {
            color: variables.$sea-80;
          }

          & > p {
            color: variables.$sea-80;
            @include variables.text-14-semi-bold
          }
        }

        & > div {
          width: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .employee_logout {
        color: variables.$black;
        text-transform: lowercase;
      }
    }

    .instructions {
      display: flex;
      column-gap: 12.6px;
      align-items: center;
      @include variables.text-14-medium;
      color: variables.$sea-80;
      cursor: pointer;

      & > div {
        width: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > p {
        text-transform: lowercase;
        color: variables.$black;
      }
    }

    .email {
      color: variables.$sea-80;
      @include variables.text-14-medium;

    }

    .line {
      @include variables.horizontal-line(1px, variables.$gray-40);
      margin-top: 16px;
    }
  }
}

#filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: variables.$black-opacity;
  z-index: 3;
}

@media (min-width: variables.$mobile) {
  .container {
    position: static;

    .bars {
      display: none;
    }

    .navigation {
      position: static;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      row-gap: 25.5px;
      padding: 0;

      &.active {
        row-gap: 25.5px;
      }

      .navigation_item {
        > a {
          color: variables.$sea-10;
          @include variables.text-18-normal;

          &.active {
            & > div {
              color: variables.$yellow-100;
            }

            & > p {
              color: variables.$white;
              @include variables.text-18-semi-bold
            }
          }
        }

        p {
          transform: translateX(2px);
          overflow: hidden;
          @include variables.default-transition;

        }

        &.log_out {
          display: none;
        }
      }

      &.expand {
        .navigation_item {
          p {
            width: fit-content;
          }
        }

      }

      .email {
        display: none;
      }

      .instructions {
        display: none;
      }
    }
  }

  .settings {
    display: flex;
  }

  #filter {
    display: none;
  }
}
