@use "src/style/variables";

.container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 5px;

  .fill_data {
    @include variables.text-16-medium;
    color: variables.$gray-60;
  }

  .line {
    @include variables.horizontal-line(1px, variables.$gray-60);
    flex-grow: 1;
  }
}
