@use "/src/style/colors";
@use "/src/style/mixins";

.three_dots_container {
  position: relative;
  border-radius: 4px;

  .dots_icon {
    cursor: pointer;

    &.disabled {
      color: colors.$gray-60;
      cursor: default;
    }
  }

  .panel_position {
    position: absolute;
    color: colors.$black;

    &.bottom_left {
      right: -2px;
    }

    &.bottom_right {
      left: 10px;
    }

    &.top_left {
      right: 10px;
      top: -90px;
    }

    &.top_right {
      left: 10px;
      top: -90px;
    }
  }
}
