@use "/src/style/variables";

.container {
  padding-bottom: 20px;
  .header_line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    .project_details {
      color: variables.$white;
      font-weight: 500;
      font-size: 28px;

      .project_name{
        font-weight: 300;
        padding-left: 10px;
      }
    }

    .button {
      padding: 11px 32px;
      display: flex;
      column-gap: 14px;
      @include variables.text-16-medium;
    }
  }

  .filters {
    flex-basis: 60%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15.5px;

    .input_container {
      flex-basis: 36%;
      min-width: 180px;

      .input {
        @include variables.text-16-medium;
        height: 46px;
      }
    }
  }
}

@media (max-width: variables.$mobile) {
  .container {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
