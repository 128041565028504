@use "src/style/variables";

.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: variables.$black-opacity;
  z-index: 99999;
}
