@use "../../../../style/variables";

$socialSecurityNumberWidth: 185px;
$passportWidth: 220px;
$menuWidth: 106px;

.container, .empty_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;

  .description {
    @include variables.text-22-medium;
    color: variables.$sea-10;
  }

  .user_container, .header_container {
    display: flex;
    align-items: center;
    padding: 14px 16px 14px;

  }

  .table_cell {
    flex-basis: calc((100% - $socialSecurityNumberWidth - $passportWidth - $menuWidth) / 3);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:nth-last-child(1) {
      flex-basis: $menuWidth;
    }
  }

  .header_container {
    .table_cell {
      @include variables.text-16-semi-bold;
      color: variables.$yellow-100;
    }
  }

  .user_container {
    &:nth-child(even) {
      background-color: variables.$sea-80;
    }

    &:nth-last-child(1) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .table_cell {
      @include variables.text-14-medium;
      color: variables.$gray-60;

      &:nth-last-child(1) {
        display: flex;
        justify-content: flex-end;
      }

    }
  }

  .user_container {
    &.active {
      .table_cell {
        color: variables.$sea-10;

      }
    }

  }

  .social_security_number {
    flex-basis: $socialSecurityNumberWidth;
  }

  .passport {
    flex-basis: $passportWidth
  }

}

.empty_container {
  justify-content: center;
  align-items: center;
}

.delete_user_container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 464px;
  @include variables.text-14-medium;

  .title {
    @include variables.text-28-medium;
  }

  .buttons_container {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;

    .cancel_button {
      background-color: transparent;
    }

    .delete_button {
      background-color: variables.$error-10;
      color: variables.$error-100
    }
  }
}
