@use "colors";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@mixin default-margin() {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
}

@mixin default-transition() {
  transition: all 0.3s ease-in-out;
}

@mixin default-icon() {
  svg {
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

@mixin default-border($width: 2px, $color: colors.$sea-40, $radius: 8px) {
  border: $width solid $color;
  border-radius: $radius;
}

@mixin transparent-btn() {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

@mixin placeholder() {
  ::placeholder {
    @content;
  }
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin scrollbar() {
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 8px;
    box-shadow: inset 0 0 0 10px;
    color: colors.$dark-10;
    background-color: inherit;
    -webkit-transition: all 0.5s ease-in-out;

    &:hover {
      color: colors.$dark-30;
      border: 3px solid transparent;
    }
  }

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin default-font() {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
}

@mixin text-32-bold {
  font-weight: 700;
  font-size: 1.95rem;
}

@mixin text-28-bold {
  font-weight: 600;
  font-size: 1.75rem;
}

@mixin text-28-medium {
  font-weight: 500;
  font-size: 1.75rem;
}

@mixin text-28-normal {
  font-weight: 400;
  font-size: 1.75rem;
}

@mixin text-22-medium {
  font-weight: 500;
  font-size: 1.55rem;
}

@mixin text-20-normal {
  font-weight: 400;
  font-size: 1.40rem;
}

@mixin text-18-bold {
  font-weight: 700;
  font-size: 1.25rem;
}

@mixin text-18-semi-bold {
  font-weight: 600;
  font-size: 1.25rem;
}

@mixin text-18-normal {
  font-weight: 400;
  font-size: 1.25rem;
}

@mixin text-16-bold {
  font-weight: 700;
  font-size: 1.15rem;
}

@mixin text-16-semi-bold {
  font-weight: 600;
  font-size: 1.15rem;
}

@mixin text-16-medium {
  font-weight: 500;
  font-size: 1.15rem;
}

@mixin text-16-normal {
  font-weight: 400;
  font-size: 1.15rem;
}

@mixin text-16-light {
  font-weight: 300;
  font-size: 1.15rem;
}

@mixin text-14-bold {
  font-weight: 700;
  font-size: 1rem;
}

@mixin text-14-semi-bold {
  font-weight: 600;
  font-size: 1rem;
}

@mixin text-14-medium {
  font-weight: 500;
  font-size: 1rem;
}

@mixin text-14-normal {
  font-weight: 400;
  font-size: 1rem;
}

@mixin text-14-light {
  font-weight: 300;
  font-size: 1rem;
}

@mixin text-12-bold {
  font-weight: 700;
  font-size: 0.85rem;
}

@mixin text-12-semi-bold {
  font-weight: 600;
  font-size: 0.85rem;
}

@mixin text-12-normal {
  font-weight: 400;
  font-size: 0.85rem;
}

@mixin text-12-light {
  font-weight: 300;
  font-size: 0.85rem;
}

@mixin text-10-bold {
  font-weight: 700;
  font-size: 0.625rem;
}

@mixin text-10-semi-bold {
  font-weight: 600;
  font-size: 0.625rem;
}

@mixin text-10-normal {
  font-weight: 400;
  font-size: 0.625rem;
}

@mixin title-6-bold {
  font-weight: 600;
  font-size: 18px;
}

@mixin shadow-none {
  box-shadow: 0 0 0 rgba(45, 45, 45, 0);
}

@mixin shadow-small($color: rgba(45, 45, 45, 0.05)) {
  box-shadow: 0 4px 16px $color;
}

@mixin shadow-small-inset-outside(
  $color: colors.$sea-40,
  $insetColor: colors.$sea-40
) {
  box-shadow: 0 4px 16px $color, inset 0px 0px 0px 1px $insetColor;
}

@mixin shadow-small-inset(
  $color: colors.$sea-40,
  $insetColor: colors.$sea-60
) {
  box-shadow: inset 0px 0px 0px 1px $insetColor;
}

@mixin switch-shadow() {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}

@mixin shadow-medium($color: rgba(0, 0, 0, 0.1)) {
  box-shadow: 0 4px 15px $color;
}

@mixin shadow-medium-inset-outside(
  $color: rgba(0, 0, 0, 0.1),
  $insetColor: colors.$sea-60
) {
  box-shadow: 0 4px 15px $color, inset 0px 0px 0px 1px $insetColor;
}

@mixin shadow-big($color: rgba(0, 0, 0, 0.2)) {
  box-shadow: 0 4px 40px $color;
}

@mixin shadow-inner($color: #22555E) {
  box-shadow: inset 4px 4px 8px $color;
}

@mixin shadow-inner-small($color: #00000014) {
  box-shadow: inset 0px 4px 8px $color;
}

@mixin fading-in-anim() {
  transition: all 0.2s ease-in-out, visibility 0s linear;
  opacity: 1;
  visibility: visible;
}

@mixin fading-out-anim() {
  transition: all 0.2s ease-in-out, visibility 0s linear 0.3s;
  opacity: 0;
  visibility: hidden;
}

@mixin focus-border($borderBasicColor: transparent, $borderWidth: 2px, $borderColor: colors.$iris-30) {
  border: 2px solid $borderBasicColor;

  &:focus-visible, &:focus, &:focus-within {
    outline: none;
    border: $borderWidth solid $borderColor;
  }
}

@mixin horizontal-line($size:1px, $color: colors.$sea-60 ) {
  border-top: $size solid $color;
  margin-top: 4px;
}

@mixin inner-border($size:2px, $color:colors.$sea-60) {
  border: none;
  box-shadow: inset 0 0 0 $size $color;
}


