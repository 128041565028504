@use "../../style/variables";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background-color: variables.$white;
  border: none;

  &:disabled {
    color: variables.$gray-50;
    background-color: variables.$gray-30;

    svg {
      color: variables.$gray-50;
    }
  }
}
