@use "src/style/variables";

$indicatorDimension: 140px;
$dotDimension: 32px;
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .indicator {
    width: $indicatorDimension;
    height: $indicatorDimension;
    border-radius: 50%;
    border: $dotDimension / 2 solid variables.$gray-20;
    animation-name: rotate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .dot {
      position: absolute;
      top: - $dotDimension / 2;
      left: 50%;
      transform: translateX(-50%);
      width: calc(($dotDimension / 2) + 2px);
      height: calc($dotDimension / 2);
      border-radius: 20px;
      background-color: variables.$sea-60;
      z-index: 1;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
