// mono colors
$white: #FFFFFF;
$black: #000000;

// grays
$gray-10: #FAFAFA;
$gray-20: #EFEFEF;
$gray-30: #DEDEDE;
$gray-40: #C0C0C0;
$gray-50: #A2A2A2;
$gray-60: #848484;
$gray-80: #666666;
$gray-100: #171717;

//opacity

$black-opacity: #000000B8;
$black-opacity-20: #00000033;
$gray-30-opacity: #DEDEDEB8;
$sea-40-opacity: #76a8b433;

// primary
$yellow-10: #FFF6C0;
$yellow-40: #FCEBB6;
$yellow-60: #FFE860;
$yellow-100: #F9D500;

// secondary
$sea-10: #DDECED;
$sea-40: #76A8B4;
$sea-50: #508997;
$sea-60: #207786;
$sea-80: #306068;
$sea-100: #053941;

// errors
$error-10: #FFF8F8;
$error-100: #CC0000;

// warnings
$warning-10: #FFFAE3;
$warning-100: #D55300;

// infos
$info-10: #F3FBFF;
$info-100: #015EC2;

// successes
$success-10: #F4FBF5;
$success-100: #017018;

$light-10: #d4d4d4;
$light-30: #dedede;
$light-60: #e8e8e8;
$light-70: #efefef;
$light-80: #fafafa;
$light-100: #ffffff;

$dark-10: #c0c0c0;
$dark-30: #a2a2a2;
$dark-60: #848484;
$dark-80: #666666;
$dark-100: #171717;

$green-20: rgba(49, 172, 6, 0.2);
$green-100: #31ac06;

$grey-50: rgba(196, 196, 196, 0.5);

$red-30: rgba(239, 71, 71, 0.3);
$red-100: #ef4747;

$red-opacity-45: rgba(239, 71, 71, 0.45);

$alert-error: #cc0000;
$alert-error-light: #fff8f8;
$alert-error-light-hover: #ffe6e6;
$alert-error-light-clicked: #fcbfbf;

$alert-warning: #a64100;
$alert-warning-light: #fffae3;

$alert-success: #017018;
$alert-success-light: #f4fbf5;

$alert-info: #015ec2;
$alert-info-light: #f3fbff;

:export {
  white: $white;
  black: $black;

  gray-10: $gray-10;
  gray-20: $gray-20;
  gray-30: $gray-30;
  gray-40: $gray-40;
  gray-50: $gray-50;
  gray-60: $gray-60;
  gray-80: $gray-80;
  gray-100: $gray-100;

  yellow-10: $yellow-10;
  yellow-40: $yellow-40;
  yellow-60: $yellow-60;
  yellow-100: $yellow-100;

  sea-10: $sea-10;
  sea-40: $sea-40;
  sea-50: $sea-50;
  sea-60: $sea-60;
  sea-100: $sea-100;

  error-10: $error-10;
  error-100: $error-100;

  warning-10: $warning-10;
  warning-100: $warning-100;

  info-10: $info-10;
  info-100: $info-100;

  success-10: $success-10;
  success-100: $success-100;

  light_10: $light-10;
  light_30: $light-30;
  light_60: $light-60;
  light_70: $light-70;
  light_80: $light-80;
  light_100: $light-100;

  dark_10: $dark-10;
  dark_30: $dark-30;
  dark_60: $dark-60;
  dark_80: $dark-80;
  dark_100: $dark-100;

  green_20: $green-20;
  green_100: $green-100;

  grey_50: $grey-50;

  red_30: $red-30;
  red_100: $red-100;

  alert_error: $alert-error;
}
