@use "../../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: variables.$yellow-100;

  .time_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time {
      @include variables.text-32-bold;

      &.locked {
        color: variables.$gray-50;
      }
    }
  }

  .icon {
    @include variables.text-18-normal;
    color: variables.$sea-100
  }
}

.buttons_container {
  display: grid;
  grid-template-columns: minmax(148px, 1fr) minmax(148px, 1fr);
  column-gap: 16px;
  row-gap: 24px;
  padding-block: 24px;
}

.popup {
  width: fit-content;
  max-width: 90vw;
}
