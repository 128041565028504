@use "/src/style/colors";
@use "/src/style/mixins";

.button {
  flex: 1;
  background-color: colors.$yellow-100;
  width: 100%;
  border: none;
  outline: none;
  user-select: none;
  padding: 17px 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: colors.$sea-100;
  margin: 0;
  transition: box-shadow 0.3s ease-in-out;
  @include mixins.shadow-medium();
  @include mixins.text-22-medium;

  &:disabled,
  &[aria-disabled="true"] {
    background-color: colors.$gray-30;
    color: colors.$gray-50;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }

  &:active {
    @include mixins.shadow-inner-small()
  }

  svg {
    margin-right: 6px;
  }
}

