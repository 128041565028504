@use "/src/style/variables";

.button {
  padding: 12px 26px;
  color: variables.$sea-100;
  background-color: variables.$sea-10;
  border: none;
  border-radius: 8px;
  @include variables.text-18-normal;

  &:active {
    @include variables.shadow-inner-small;
  }

  &:disabled, &[aria-disabled="true"] {
    background-color: variables.$gray-20;
    color: variables.$gray-40
  }
}
