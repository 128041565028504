@use "src/style/variables";

.container {
  flex-grow: 1;
  padding-inline: 8px;

  .loader {
    height: 770px;
  }

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin-bottom: 36px;

    .input {
      @include variables.text-16-medium;
      color: variables.$black;
      height: 46px;
    }

    .dropdown_container {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      .dropdown {
        height: 46px;
      }

      .dropdown_value {
        @include variables.text-16-medium;
        padding-block: 22.5px 22.5px;
        padding-left: 16px;
      }

      .dropdown_wrapper {
        border: 1px solid variables.$sea-40;

        .dropdown_options {
          max-height: 205px;
        }
      }
    }

    .label {
      @include variables.text-16-medium;
    }

  }

  .user_state_container {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;

    .switch_container {
      width: 168px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      svg {
        color: variables.$sea-10;
        @include variables.text-18-normal;
      }

      .switch {
        scale: 1.35;
      }

      .active {
        color: variables.$sea-60;
      }
    }
  }

  .upload {
    @include variables.text-16-medium;
    color: variables.$sea-60;
    margin-block: 27px 32px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .upload_file_label {
    @include variables.text-16-medium;
    color: variables.$sea-60;
    margin-block: 11px 32px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .hidden_input {
    display: none;
  }

}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.button {
  padding-inline: 52px;
}

@media (max-width: variables.$mobile) {
  .container {
    min-width: 85vw;
  }
}

@media (max-height: 997px)
{
  .button_container {
    padding-bottom: 16px;
  }
}
