@use "../../../src/style/variables";

.container {

  .children_container {
    position: fixed;
    padding: 16px;
    background-color: variables.$sea-100;
    left: 0;
    width: 100vw;
  }
}

@media (min-width: variables.$mobile) {
  .container {
    .children_container {
      position: static;
      width: 100%
    }
  }
}
