@use "src/style/variables";

.container {
  padding-inline: 8px;
  max-width: 416px;

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .message {
    padding: 30px 0 45px 0;
    @include variables.text-14-medium;
  }

  .button_container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .cancel_button {
      background-color: transparent;
      width: 120px;
    }

    .accept_button {
      width: 120px;
    }
  }
}

@media (max-width: variables.$mobile) {
  .container {
    min-width: 85vw;
  }
}
