@use "src/style/variables";

$minCompanyContainerHeight : 816px;

.container {
  display: flex;
  flex-direction: column;
  padding-inline: 8px;
  height: $minCompanyContainerHeight;
  row-gap: 8px;

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 36px;
    height: 505px;

    .input_container {
      margin-bottom: 9px;
    }

    .input {
      @include variables.text-16-medium;
      color: variables.$black;
      height: 46px;
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end;
    .button{
      padding-inline: 52px;
    }
  }
}

@media (max-width: variables.$mobile) {
  .container {
    min-width: 85vw;
  }
}

@media (max-height: calc( $minCompanyContainerHeight + (2 * variables.$popupPadding) ))
{
  .button_container {
    padding-bottom: 16px;
  }
}
