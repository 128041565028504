@use "variables";

.react-datepicker {

  .react-datepicker__month-container {
    width: 100%;

    .react-datepicker__header {
      padding-top: 12px;
      padding-bottom: 6px;
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      background-color: variables.$sea-10;
      border-bottom: 1px solid variables.$sea-60;

      .react-datepicker__header__dropdown {
        display: none;
      }

      .react-datepicker__current-month {
        text-transform: capitalize;
        @include variables.text-18-semi-bold;
        color: variables.$sea-100;
      }
    }

    .react-datepicker__day-names, .react-datepicker__week {
      display: flex;
      justify-content: center;

      .react-datepicker__day-name, .react-datepicker__day {
        flex-grow: 1;
        color: variables.$sea-100;
        @include variables.text-16-semi-bold;
        text-transform: capitalize;
      }

      .react-datepicker__day {
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
      }

      .react-datepicker__day--outside-month {
        color: variables.$sea-40;
      }

      .react-datepicker__day--today {
        background-color: variables.$sea-10;
      }

      .react-datepicker__day--disabled {
        color: variables.$gray-50;
        background-color: variables.$gray-20;
      }

      .react-datepicker__day--selected {
        border: 2px solid variables.$sea-60;
        background-color: transparent;

        &.react-datepicker__day--today {
          background-color: variables.$sea-10;
        }
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        background-color: variables.$sea-10;
      }

      .react-datepicker__day--range-end {
        border: 2px solid variables.$sea-60;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
      }
    }

    .react-datepicker__week {
      column-gap: 9px;
    }

    .react-datepicker__month {
      margin: 0;
    }
  }

  .react-datepicker__navigation {
    margin-top: 9px;
    width: 32px;
    height: 32px;
    background-color: variables.$white;
    border-radius: 50%;

    .react-datepicker__navigation-icon {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      &::before {
        content: none;
      }
    }
  }

  .react-datepicker__navigation--previous {
    left: 8px
  }

  .react-datepicker__navigation--next {
    right: 8px
  }

  .react-datepicker__triangle {
    display: none;
  }

}
