@use "../../style/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
  padding-block: 13px;
  border-radius: 8px;
  background-color: variables.$sea-10;
  color: variables.$sea-100;
  column-gap: 11px;
  @include variables.text-18-normal;
  cursor: pointer;

  &.disabled {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: variables.$gray-30-opacity;
      @include variables.shadow-inner-small;
      cursor: default;
    }
  }
}
