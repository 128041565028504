@use "src/style/variables";

$socialSecurityNumberWidth: 150px;
$passportWidth: 190px;

.container {
  display: flex;
  flex-direction: column;
  min-width: 80vw;
  height: 925px;

  .title {
    @include variables.text-28-medium;
    margin-bottom: 24px;
  }

  .input {
    max-width: 460px;
    margin-bottom: 19px;
  }

  .table_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 598px;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-bottom: 32px;

    &.loading {
      position: relative;

      .header_container, .users_container {
        opacity: 0;
      }
    }

    .users_container {
      flex-grow: 1;
      overflow-y: scroll;

    }

    .user_container, .header_container {
      display: flex;
      align-items: center;
      padding: 14px 16px 14px;

    }

    .table_cell {
      flex-basis: calc((100% - $socialSecurityNumberWidth - $passportWidth) / 4);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: variables.$sea-100;
      @include variables.text-14-medium;

      &.social_security_number {
        flex-basis: $socialSecurityNumberWidth;
      }

      &.passport {
        flex-basis: $passportWidth;
      }
    }

    .header_container {
      padding-right: 32px;

      .table_cell {
        @include variables.text-16-semi-bold;
        color: variables.$sea-80;
      }

    }

    .user_container {
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:nth-child(odd) {
        background-color: variables.$sea-10;
      }

      &:nth-child(even) {
        background-color: variables.$gray-10;
      }
    }

    .selected_users_count {
      margin-left: 4px;
      color: variables.$sea-40;
    }
  }

  .button {
    align-self: flex-end;
    padding-inline: 52px;
  }
}
