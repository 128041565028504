@use "src/style/colors";
@use "/src/style/mixins";

.notification_container {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 32px 8px 8px;

  .icon {
    min-width: 12px;
    margin: 0 2px;
    align-self: start;
  }

  .text {
    @include mixins.text-12-normal();

  }

  .close_icon {
    fill: colors.$dark-80;
    margin: 0 2px;
    position: absolute;
    top: 9px;
    right: 8px;
    cursor: pointer;
  }

  &.error {
    background-color: colors.$alert-error-light;
    .icon {
      fill: colors.$alert-error;
    }
    .text {
      color: colors.$alert-error;
    }
  }

  &.warning {
    background-color: colors.$alert-warning-light;
    .icon {
      fill: colors.$alert-warning;
    }
    .text {
      color: colors.$alert-warning;
    }
  }

  &.success {
    background-color: colors.$alert-success-light;
    .icon {
      fill: colors.$alert-success
    }
    .text {
      color: colors.$alert-success
    }
  }

  &.info {
    background-color: colors.$alert-info-light;
    .icon {
      fill: colors.$alert-info
    }
    .text {
      color: colors.$alert-info
    }
  }

}
