@use "src/style/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: variables.$sea-100;
  width: 100%;
  height: 100%;

  .login_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    padding: 24px 16px;
    color: variables.$sea-100;
    overflow-y: auto;

    .logo {
      width: 241px;
      height: 46px;
    }

    .button_container {
      width: 544px;
    }
  }

  .login_form_wrapper {
    position: relative;
    width: fit-content;
  }

  .container_shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: variables.$yellow-100;
    border-radius: 8px;
    transform: translate(6px, 6px);
    z-index: 0;
  }

  .children_container {
    max-width: 544px;
    padding: 60px 120px;
    background-color: variables.$white;
    border-radius: 8px;
    position: relative;
    z-index: 1;

    .hidden {
      opacity: 0;
    }
  }
}

@media(max-width: variables.$mobile) {
  .wrapper {
    .login_container {
      .login_form_wrapper {
        width: 100%;

        .children_container {
          max-width: none;
        }
      }

      .button_container {
        width: 100%;
      }
    }

    .children_container {
      padding: 32px 12px;
    }
  }
}
