@font-face {
  font-family: 'TTHoves';
  font-weight: 100;
  src: url("../../public/fonts/TTHoves-Hairline.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 200;
  src: url("../../public/fonts/TTHoves-ExtraLight.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 300;
  src: url("../../public/fonts/TTHoves-Light.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 400;
  src: url("../../public/fonts/TTHoves-Regular.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 500 ;
  src: url("../../public/fonts/TTHoves-Medium.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 600 ;
  src: url("../../public/fonts/TTHoves-DemiBold.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 700 ;
  src: url("../../public/fonts/TTHoves-Bold.ttf");
}

@font-face {
  font-family: 'TTHoves';
  font-weight: 800 ;
  src: url("../../public/fonts/TTHoves-ExtraBold.ttf");
}
