@import "colors";
@import "mixins";

$navbar-height: 48px;
$sidebar-width: 56px;
$sidebar-extended-width: 224px;
$filters-zone-height: 86px;

// screen dimensions //

//width
$tablet: 1280px;
$mobile: 640px;

// popup //
$popupPadding:24px;
