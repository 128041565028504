@use "../../style/variables";

.container {
  .header_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    .title {
      @include variables.text-14-semi-bold;
      color: variables.$yellow-100;
      white-space: nowrap;
    }

    .total {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      column-gap: 5.2px;

      .total_week {
        @include variables.text-10-semi-bold;
        color: variables.$sea-40;
        white-space: nowrap;
        transform: translateY(0.13rem);
      }

      .total_time {
        @include variables.text-14-semi-bold;
        color: variables.$yellow-100;
      }
    }

  }

  &.month {
    .header_container {
      .title {
        text-transform: capitalize;
        @include variables.text-18-semi-bold;
      }

      .total {

        .total_week {
          @include variables.text-12-semi-bold;
          transform: translateY(0.1rem);
        }

        .total_time {
          @include variables.text-16-semi-bold;
        }
      }
    }
  }

  .line {
    @include variables.horizontal-line;
  }
}
