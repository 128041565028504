@use "../../../src/style/variables";

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: variables.$black-opacity;
  z-index: 9998;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    .popup {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 12px;
      background-color: variables.$white;
      border-radius: 8px;
      overflow-y: auto;


      .child_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &.hide_children {
          opacity: 0;
        }
      }

      .icon {
        margin: 4px;
        @include variables.text-28-normal;
        align-self: flex-end;
        cursor: pointer;
      }

    }
  }
}
.popup{
  width: 464px;
}

@media (min-width: variables.$mobile) {
  .container {
    .wrapper {
      .popup {
        padding: variables.$popupPadding
      }
    }
  }
}
