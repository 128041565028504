@use "/src/style/variables";

.container {
  display: flex;
  justify-content: space-between;
  column-gap: 143px;
  margin-bottom: 26px;

  .filters {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15.5px;

    .date_picker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      min-width: 280px;
      background-color: variables.$white;
      border-radius: 8px;
      padding: 9.2px 12px 9.2px 12px;
      cursor: pointer;
      color: variables.$sea-100;
      border: 1px solid variables.$sea-60;

      .date_range {
        display: flex;
        align-items: center;
        column-gap: 12px;
        @include variables.text-16-medium;

        > div {
          height: 24px;
          width: 1px;
          background-color: variables.$sea-40;
        }
      }

      .calendar_icon {
        color: variables.$sea-80
      }
    }
  }

  .button {
    padding: 11px 32px;
    display: flex;
    column-gap: 14px;
    @include variables.text-16-medium;
  }
}

.popup {
  width: fit-content;
  max-width: 90vh;
}

.calendar_container {
  min-height: 320px;
  margin-top: 8px;

  .calendar_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
  }

  .date_wrapper {
    display: none;
  }

  .date_input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .calendar {
    min-width: 312px;
    border: 1px solid variables.$sea-60;
  }

  .popper {
    position: relative !important;
    transform: none !important;
    padding-top: 0 !important;
  }
}

@media (max-width: variables.$mobile) {
  .container {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
