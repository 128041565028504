@use "../../style/variables";

.table {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;

  .group {
    &:nth-child(even) {
      background-color: variables.$sea-80;
    }

    &:nth-last-child(1) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
    }

    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
    }
  }

  .header_container {
    margin-bottom: 1px;
  }

  .header_container, .group {
    display: flex;
    align-items: center;
    padding-right: 16px;

    .header_name, .header, .header_total {
      @include variables.text-14-normal;
    }

    .name {
      max-width: 168px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .header, .group_item, .total_item, .header_total, .group_total, .total {
      flex-shrink: 0;
      flex-basis: 46px;
      text-align: center;
    }

    .header_total, .group_total {
      text-align: end;
      flex-basis: 52px;
    }

    .header_name, .group_name {
      flex-grow: 1;
      padding: 14px 16px;
    }

    .total_item, .total_name, .total {
      @include variables.text-12-bold;
    }

    .group_name {
      @include variables.text-14-medium;
      color: variables.$white;
    }

    .header_total {
      color: variables.$yellow-100;
      text-transform: uppercase;
    }

    .header_name {
      @include variables.text-18-semi-bold;
      color: variables.$yellow-100;
    }

    .header {
      @include variables.text-14-medium;
      color: variables.$sea-10;
    }

    .group_total {
      @include variables.text-12-bold;
      color: variables.$white
    }
  }

  .group_item {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$sea-10;
    cursor: pointer;

    &.holiday {
      background-color: variables.$sea-40-opacity;
    }

    &.absence {
      @include variables.text-12-bold;
      text-transform: lowercase;
    }

    &.modified {
      color: variables.$yellow-100;
    }

    &:hover {
      background-color: variables.$black-opacity-20;
      color: variables.$yellow-100;
    }
  }

  .group_item, .group_total {
    @include variables.text-12-normal;

  }

  .group_container {
    display: flex;
    flex-direction: column;
  }

}

@media (max-width: 1760px) {
  .table {
    .header_container, .group {
      .header, .group_item, .total_item {
        display: none;
      }
    }
  }
}
