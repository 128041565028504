@use "src/style/variables";

.container {
  display: none;

  .content_container {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 4px 16px;
    align-items: center;

    .wrapper {
      display: flex;
      column-gap: 20px;

      .switch_container {
        display: none;
      }
    }

    span {
      a {
        color: variables.$white;
        cursor: pointer;

        &:hover {
          color: variables.$white;
        }
      }
    }

    .log_out_container {
      display: flex;
      align-items: center;
      column-gap: 8px;
      cursor: pointer;
      position: relative;

      &.show_menu {
        .expand_icon {
          transform: rotate(180deg);
        }

      }

      .icon_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: variables.$sea-40;
        color: variables.$black;
      }

      .expand_icon {
        color: variables.$sea-40;
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;
      row-gap: 25.5px;
      position: absolute;
      top: 48px;
      right: -16px;
      text-decoration: none;
      padding: 0;
      height: 0;
      overflow: hidden;
      background-color: variables.$white;
      z-index: 2;

      &.show_menu {
        height: fit-content;
        padding: 22px 16px 20px;
        border-bottom-left-radius: 8px;
        min-width: 258px;
      }

      .instructions {
        display: flex;
        column-gap: 12.6px;
        align-items: center;
        @include variables.text-14-medium;
        color: variables.$sea-80;
        cursor: pointer;

        & > div {
          width: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > p {
          text-transform: lowercase;
          color: variables.$black;
        }
      }

      li {
        list-style: none;
      }

      .navigation_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 30px;

        > a {
          display: flex;
          align-items: center;
          column-gap: 12.6px;
          color: variables.$sea-80;
          white-space: nowrap;
          @include variables.text-14-medium;
          text-decoration: none;

          & > div {
            width: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &.log_out {
          p {
            color: variables.$black;
            text-transform: lowercase;
          }
        }
      }

      .email {
        color: variables.$sea-80;
        @include variables.text-14-medium;

        .line {
          @include variables.horizontal-line(1px, variables.$gray-40);
          margin-top: 16px;
        }
      }
    }
  }

  .line {
    @include variables.horizontal-line(1px, variables.$sea-80)
  }
}

@media (min-width: variables.$mobile) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content_container {
      .wrapper {
        .switch_container {
          display: flex;
          align-items: center;
          column-gap: 4px;
          color: variables.$white;
          @include variables.text-16-semi-bold;
        }
      }
    }
  }
}
