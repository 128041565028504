@use "src/style/variables";

$minScreenHeightForPopup:997px;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup {
  flex-grow: 1;
}

@media (min-height: $minScreenHeightForPopup)
{
  .popup {
    min-height: $minScreenHeightForPopup;
  }
}
