@use "/src/style/variables";

.container {
  display: flex;
  flex-direction: column;

  .header {
    @include variables.text-18-semi-bold;
    color: variables.$yellow-100;
    margin-bottom: 16px;
  }

  .settings_container {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;
  }
}
