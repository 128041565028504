@use "./style/fonts";
@import "./style/variables";

html,
body,
#root {
  font-family: TTHoves, Arial, sans-serif;
  @include default-margin();
  @include scrollbar();
  background: $sea-100;
  height: 100vh;
}

p,
span {
  @include default-margin();
}

.breadcrumb_separator {
  color: $white;
}

html {
  overflow: hidden;
}

body {
  line-height: 1.5;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*, :after, :before {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  background-color: transparent;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
