@use "../../../style/variables";

.container {
  color: variables.$yellow-100;

  .title_container {
    display: flex;
    align-items: center;
    column-gap: 10.4px;

    .title {
      @include variables.text-18-semi-bold;
    }

    .info_icon {
      cursor: pointer;
      @include variables.text-18-normal;
    }
  }

  .line {
    @include variables.horizontal-line;
  }
}

.popup {
  width: fit-content;
  max-width: 90vw;
}
