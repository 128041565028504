@use "../../style/mixins";
@use "../../style/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .header_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 29px;

    .dropdown_container {
      width: 180px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
