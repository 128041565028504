@use "src/style/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  color: variables.$black;
  cursor: pointer;

  .button_container {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid variables.$sea-60;

    .check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 15px;

      &.marked {
        background-color: variables.$sea-60;
      }
    }
  }

  .text {
    @include variables.text-16-medium
  }
}
