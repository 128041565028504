@use "/src/style/variables";

.input_container {
  flex-basis: 28%;
  min-width: 140px;

  .input {
    @include variables.text-16-medium;
    height: 46px;
  }
}

.dropdown_container {
  flex-basis: 15%;
  min-width: 160px;

  &:nth-child(2) {
    min-width: 240px;
  }

  .dropdown {
    height: 46px !important;

    .dropdown_value {
      @include variables.text-16-medium;
      padding-block: 22px;
      padding-left: 16px;
    }
  }
}
