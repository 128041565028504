@use "/src/style/variables";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: variables.$sea-80;
  padding-inline: 12px;

  .description {
    @include variables.text-18-normal;
    color: variables.$white;
  }

  .buttons_container {
    display: flex;
    column-gap: 8px;
    padding-block: 12px;

    .time_value {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 32px;
      background-color: variables.$sea-40;
      border-radius: 8px;
      @include variables.text-12-normal;
      color: variables.$sea-100
    }

    .button {
      width: 32px;
      height: 32px;
    }

  }
}

.icon {
  @include variables.text-12-normal;
  color: variables.$sea-100
}
