@use "../../../style/variables";

$menuWidth: 106px;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;

  .company_container, .header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px 14px;
  }

  .table_cell {
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      flex-basis: 25%;
      justify-content: flex-start;
    }

    &:nth-last-child(1) {
      flex-basis: $menuWidth;
    }
  }

  .header_container {
    .table_cell {
      @include variables.text-16-semi-bold;
      color: variables.$yellow-100;
    }
  }

  .company_container {
    &:nth-child(even) {
      background-color: variables.$sea-80;
    }

    &:nth-last-child(1) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .table_cell {
      @include variables.text-14-medium;
      color: variables.$sea-10;

      &:nth-last-child(1) {
        display: flex;
        justify-content: flex-end;
      }

    }
  }
}
