@use "src/style/variables";

$borderColor: variables.$sea-60;
$placeholderColor: variables.$sea-40;

.container {
  width: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;

  &.edit {
    height: 38px;
  }

  &.disabled {
    border-radius: 8px;
    pointer-events: none;
    background-color: variables.$light-70;
  }

  .wrapper {
    position: absolute;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    border: 1px solid $borderColor;
    white-space: nowrap;
    background-color: variables.$white;

    &:focus,
    &:focus-within {
      border-color: $borderColor;
      @include variables.shadow-small-inset($borderColor);
      background-color: variables.$white;

      .dropdown {
        .item {
          border-inline: 1px solid $borderColor;
        }
      }
    }

    &:hover {
      cursor: default;
    }

    &.expand {
      z-index: 11;

      &:hover {
        cursor: pointer;
      }
    }

    &.edit {
      outline-color: variables.$sea-60;

      &.error {
        outline-color: variables.$error-100
      }

      &:hover {
        cursor: pointer;
      }
    }

    .value {
      color: variables.$sea-100;
      padding: 8px 16px 7px 16px;

      input {
        color: variables.$sea-100;
      }
    }

    &.disabled {
      outline-color: transparent;
      border-color: variables.$gray-30;

      .selected {
        color: variables.$gray-80;
      }

      & > div:nth-last-child(1) {
        display: none;
      }

    }

    .value {
      @include variables.text-14-medium;
      position: relative;
      width: 100%;
      height: 36px;
      border-radius: 8px;
      z-index: 5;
      margin-block: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline: 10px 30px;
      color: variables.$black;
      user-select: text;
      transition: all 0.2s ease-in-out;

      input {
        transition: all 0.2s ease-in-out;
        width: 100%;
        border: none;
        outline: none;
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.disabled {
        background-color: variables.$light-60;
        color: variables.$dark-60;

      }

      .expand_icon {
        transition: all 0.2s ease-in-out;
        right: 8px;
        margin-top: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        color: variables.$black;

        &.expand {
          transform: scale(1) rotateZ(-180deg) translateY(50%);
        }

        &.disabled {
          color: variables.$gray-80;
        }

        &.edit {
          opacity: 1;
        }
      }
    }

    .dropdown {
      border-radius: 0 0 4px 4px;
      position: relative;
      height: fit-content;
      max-height: 0;
      transition: all 0.2s ease-in-out;
      opacity: 0;
      border-top: 1px solid variables.$sea-60;
      flex-direction: column;
      column-gap: 1px;
      overflow: auto;

      &.expand {
        max-height: 220px;
        opacity: 1;
      }

      .item {
        flex-shrink: 0;
        height: 34px;
        width: 100%;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        gap: 2px;
        position: relative;
        color: variables.$sea-100;
        border-inline: 1px solid transparent;
        @include variables.text-14-light;

        > div {
          position: absolute;
          display: inline-block;
          max-width: calc(100% - 16px);
          overflow: hidden;
        }

        &.disabled {
          color: variables.$gray-60
        }

        &:hover {
          cursor: pointer;
          background-color: variables.$gray-20;
        }

        &:focus,
        &:focus-visible {
          background-color: variables.$sea-10;
          outline: none;
        }
      }
    }

    .selected {
      font-weight: 600;
      color: variables.$sea-100;
    }

    .error_label {
      position: absolute;
      color: variables.$error-100;
      font-size: 0.7rem;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}

.overlay {
  inset: 0;
  position: absolute;
  z-index: 4;
}
