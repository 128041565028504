@use "../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 16px;

  .entries_container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .entry {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      background-color: variables.$white;
      color: variables.$black;
      cursor: pointer;

      .date {
        @include variables.text-14-medium;
        text-transform: capitalize;
      }

      .daily_time {
        @include variables.text-14-semi-bold
      }

      &:active {
        @include variables.shadow-inner-small;
      }

      &.locked {
        position: relative;
        cursor: initial;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: variables.$gray-30-opacity;
          @include variables.shadow-inner-small;

        }
      }
    }
  }
}
