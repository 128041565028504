@use "../../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  column-gap: 16px;

  .absences_title {
    color: variables.$sea-100
  }

  .absences_container {
    padding: 20px 2px 51px 2px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 8px;
    row-gap: 22px;
    color: variables.$black;
    @include variables.text-18-bold;

    .absence_short {
      display: flex;
      align-items: center;
      column-gap: 9.2px;
      align-self: flex-start;
    }
  }

  .absence_full_name {
    display: flex;
    column-gap: 2px;
  }
}


