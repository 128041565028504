@use "../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

}

.buttons_container {
  display: flex;
  column-gap: 16px;

  .button_text, .button_cancel {
    @include variables.text-22-medium;

    &.disabled {
      background-color: variables.$gray-30;
      color: variables.$gray-50;
      box-shadow: none;
    }
  }

}

.button_cancel {
  background-color: variables.$white;
}
