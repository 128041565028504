@use "src/style/variables";

$minProjectContainerHeight :816px ;
$popupPadding: 24px;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-inline: 8px;
  min-width: 416px;
  min-height: $minProjectContainerHeight;

  .loader {
    height: 770px;
  }

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin-bottom: 36px;
    padding-top: 10px;
    height: 505px;

    .input {
      @include variables.text-16-medium;
      color: variables.$black;
      height: 46px;
    }

    .dropdown_container {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      .label {
        @include variables.text-16-medium;
      }

      .dropdown_value {
        @include variables.text-16-medium;
        padding-block: 22.5px 22.5px;
        padding-left: 16px;
      }

      .dropdown_wrapper {
      }
    }
  }

  .employees_button_container {
    padding-top: 30px;
    padding-bottom: 30px;

    .link {
      padding-top: 20px;
      text-align: center;
      color: variables.$sea-60;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end;

    .button {
      padding-inline: 52px;
    }
  }
}

@media (max-width: variables.$mobile) {
  .container {
    min-width: 85vw;
  }
}

@media (max-height:calc( $minProjectContainerHeight + (2 * $popupPadding) ))
{
  .button_container {
    padding-bottom: 16px;
  }
}
