@use "../../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: variables.$yellow-100;

  .date_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      @include variables.text-32-bold;
    }
  }
}

.icon {
  @include variables.text-16-normal;
  color: variables.$sea-100;
}

.calendar_container {
  min-height: 320px;
  margin-top: 8px;

  .date_wrapper {
    display: none;
  }

  .date_input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .calendar {
    min-width: 312px;
    border: 1px solid variables.$sea-60;
  }

  .popper {
    position: relative !important;
    transform: none !important;
    padding-top: 0 !important;
  }
}

.calendar_button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
}

.popup {
  width: fit-content;
  max-width: 90vw;
}
