@use "/src/style/colors";
@use "/src/style/mixins";

.tools {
  @include mixins.shadow-small();
  width: fit-content;
  height: fit-content;
  background-color: colors.$light-100;
  border-radius: 8px;
  @include mixins.default-transition();
  overflow: hidden;
  position: absolute;
  padding-block: 8px;
  z-index: 6;
  top: 40px;

  .item {
    border: 2px solid transparent;
    padding: 4px 16px;
    @include mixins.default-transition();
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
      white-space: nowrap;
    }

    &:hover, &:focus, &:focus-visible {
      cursor: pointer;
      background-color: colors.$gray-30;
    }

    .icon {
      margin-bottom: 2px;

    }

    .active {
      color: colors.$yellow-100;
    }
  }
}
