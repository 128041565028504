@use "src/style/variables";

.container {
  position: relative;
  width: 100%;
  background-color: variables.$white;
  z-index: 1;
  color: variables.$sea-100;

  .line {
    @include variables.horizontal-line;
  }

  .title {
    @include variables.text-18-semi-bold;
  }

  .section_container {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    margin: 28px 2px 0 8px;

    .title {
      display: flex;
      align-items: center;
      color: variables.$sea-40;
      @include variables.text-12-normal;

      p {
        white-space: nowrap;
        flex-basis: 120px;
      }

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: variables.$sea-40;
        margin-right: 4px;

      }

      .line {
        flex-grow: 1;
        @include variables.horizontal-line(1px, variables.$sea-40);
        margin-top: 0;
      }
    }

    .radio_buttons {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    .consent_container {
      display: flex;
      align-items: flex-start;
      column-gap: 8px;

      .consent {
        @include variables.text-12-normal;

        a {
          color: variables.$info-100;
          text-decoration: underline;
        }
      }
    }
  }

}
