@use "src/style/variables";
@use "src/style/mixins";

.content {
  flex: 1 1;
  transition: margin-left 0.2s ease-in-out;
  display: flex;
  flex-direction: column;

  .content_container {
    flex-grow: 1;
    margin: 0px 16px 16px 16px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: auto;
  background-color: variables.$sea-100;
  scroll-behavior: smooth;

  .logo {
    width: 103px
  }

  .empty_container {
    margin-bottom: 24px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding: 8px 16px;
    background-color: variables.$sea-100;
    z-index: 9997;

    .wrapper {
      display: flex;
      column-gap: 24px;
      color: variables.$white;
      @include mixins.text-16-semi-bold;

      .navigation_container {
        display: flex;
        align-items: center;
      }

      .switch_container {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }

      .settings, .expand_arrow {
        display: none;
      }
    }
  }

}

.instruction_container {
  display: flex;
  flex-direction: column;

  > p {
    @include mixins.text-18-semi-bold;
    color: variables.$sea-100;
  }

  .line {
    @include mixins.horizontal-line();
    margin-bottom: 16px;
  }

  .instructions_list {
    @include mixins.text-12-normal;
    padding-left: 14px;
    max-width: 340px;

    > li {
      padding-left: 2px;
    }
  }
}

.popup {
  width: fit-content;
  max-width: 90vw;
}

@media (min-width: variables.$mobile) {
  .layout {
    flex-direction: row;
    overflow: hidden;
    padding: 0;

    .logo {
      width: 153px;
      transform: translateX(-9px);
    }

    .container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 56px;
      padding: 12px 16px 22px 16px;
      position: static;
      overflow: hidden;
      width: 53px;
      background-color: variables.$sea-80;
      @include mixins.default-transition;

      // hide sidebar on desktop for employees, until they don't have any navigation options

      &.employee_navigation {
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        width: 145px;
        padding-top: 12px;
        padding-left: 24px;

        .logo {
          width: 100%;
        }

        .wrapper {
          display: none;
        }
      }

      //

      &.expanded {
        width: 224px;

        .wrapper {
          .settings {
            display: flex;
          }

          .expand_arrow {
            transform: rotate(180deg);
          }
        }
      }

      .wrapper {
        align-self: stretch;
        flex-grow: 1;
        display: flex;
        position: relative;

        .navigation_container {
          flex-grow: 1;
          align-items: flex-start;
          align-self: stretch;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .switch_container {
          display: none;
        }

        .settings {
          width: 100%;
          display: none;

        }

        .expand_arrow {
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;
        }
      }
    }

    .content {
      .content_container {
        flex-grow: 1;
        overflow: auto;
        padding: 26px 16px 16px 16px;
        margin: 0;
        scroll-behavior: smooth;
      }

      .portal_container {
        position: relative;
      }
    }
  }
}
