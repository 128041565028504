@use "src/style/variables";

.container {
  flex-grow: 1;
  padding-inline: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 44px;

  .title {
    color: variables.$black;
    @include variables.text-28-medium;
  }

  .inputs_container {
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    input {
      height: 46px;
      @include variables.text-16-medium;
    }

    .input_email, .input_password {
      color: variables.$info-100;

      svg {
        cursor: pointer;
      }

      input {
        background-color: variables.$info-10;
        border: 1px solid #CDE5FE;
        color: variables.$black;
        cursor: pointer;
      }
    }
  }

  .warnings {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .warning_container, .info_container, .error_container {
      display: flex;
      column-gap: 8px;
      padding: 12px 8px;
      color: variables.$warning-100;
      background-color: variables.$warning-10;
      @include variables.text-12-normal;

      .warning_icon {
        margin-top: 2px;
      }
    }

    .error_container {
      color: variables.$error-100;
      background-color: variables.$error-10;

    }

    .info_container {
      color: variables.$success-100;
      background-color: variables.$success-10
    }
  }

}

.buttons_container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  .button {
    background-color: transparent;
  }
}

@media (max-height: 997px)
{
  .buttons_container {
    padding-bottom: 16px;
  }
}
