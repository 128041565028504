@use "../../../style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: variables.$yellow-100;

  .buttons_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px 14px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10.4px;
    }
  }
}
