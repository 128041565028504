@use "src/style/colors";
@use "src/style/mixins";

$borderColor: colors.$sea-60;
$placeholderColor: colors.$sea-40;

.input_container {
  @include mixins.text-14-normal;
  color: colors.$sea-100;
  display: inline-block;
  width: 100%;
  box-sizing: content-box;

  .input {
    width: 100%;
    height: 38px;
    color: colors.$sea-100;
    border: 1px solid $borderColor;
    border-radius: 8px;
    background-color: colors.$white;
    padding: 8px 16px 7px 16px;
    box-sizing: border-box;
    @include mixins.default-transition;

    &:hover {
      @include mixins.shadow-medium;
    }

    &::placeholder {
      color: $placeholderColor;
    }

    &:focus-visible {
      @include mixins.shadow-medium-inset-outside(transparent, $borderColor);
      outline: none;
    }

    &.icon_right {
      padding-right: 36px;
    }

    &.error {
      padding-right: 36px;
      border: 1px solid colors.$error-100;
    }

    &.error:focus-visible {
      box-shadow: inset 0px 0px 0px 1px colors.$alert-error;
    }

    &:disabled {
      border: 1px solid colors.$gray-30;
      color: colors.$gray-60;
      background-color: colors.$gray-20;

      &:hover {
        box-shadow: none;
      }

      &::placeholder {
        color: $placeholderColor;
      }
    }

    &.borderless {
      border-color: transparent;

      &:focus-visible {
        border-color: $borderColor;
        @include mixins.shadow-medium-inset-outside(transparent, $borderColor);
      }

      &.hovering {
        border-color: $borderColor;
        box-shadow: none;

        &:focus-visible {
          border-color: $borderColor;
          @include mixins.shadow-medium-inset-outside(transparent, $borderColor);
        }
      }
    }
  }

  .label {
    padding-bottom: 2px;
    @include mixins.text-16-medium;
  }

  .error_label {
    font-size: 0.7rem;
    color: colors.$alert-error;
  }

  .input_alert_container {
    position: relative;

    .icon,
    .icon_error,
    .children_icon,
    .icon_eye {
      font-size: 16px;
      position: absolute;
      right: 9px;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon_eye {
      cursor: pointer;
    }

    .icon_error {
      color: colors.$alert-error;
    }
  }
}
