@use "/src/style/colors";
@use "/src/style/mixins";

.container {
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  &:hover .checkbox {
    cursor: pointer;
  }
}

.checkbox {
  background-color: colors.$white;
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  margin-top: 2px;
  border: 2px solid colors.$sea-60;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  color: colors.$white;
  @include mixins.text-12-normal;

  &:hover {
    cursor: pointer;
  }
}

.checked {
  background-color: colors.$sea-60;
}

.label {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }
}


