@use "src/style/mixins";
@use "src/style/variables";
@use "src/style/colors";

.inputs_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 13px;

  .inputs_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .input {
    color: colors.$black
  }

  .title {
    align-self: stretch;
    @include mixins.text-18-semi-bold;

    .line {
      @include mixins.horizontal-line;
    }
  }

  .reset_password_container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    .password_reset {
      @include mixins.text-12-semi-bold;
      color: colors.$sea-60;
      text-decoration: none;
    }

    p {
      @include mixins.text-12-normal;
      color: colors.$info-100;
      text-decoration: underline;
    }
  }
}

.checkbox_container {
  align-self: flex-start;
  padding-bottom: 12px;
  color: colors.$dark-100;
}

.button {
  padding: 8px;

  &:focus-visible {
    box-shadow: 0 3px 14px rgba(116, 0, 184, 0.5);
  }

  span {
    @include mixins.text-12-bold;
    margin-left: 5px;
  }

  div {
    @include mixins.text-12-normal;
  }
}

.error_messages_wrapper {
  opacity: 0;
  width: 304px;
  min-height: 23px;

  &.active {
    opacity: 1;
  }

  .wrong_login_data {
    display: flex;
    justify-content: space-between;
    @include mixins.text-12-normal;
    background-color: #fef8f8;
    color: colors.$alert-error;
    padding: 1rem 0.5rem;

    span {
      flex-basis: 85%;
    }
  }
}

.close_icon {
  @include mixins.text-14-normal;
  cursor: pointer;
  color: colors.$sea-60;
}

.exclamation_icon {
  @include mixins.text-14-normal;
  color: colors.$alert-error;
}
