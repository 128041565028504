@use "/src/style/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .title {
    @include variables.text-28-medium;
    color: variables.$black;
  }

  .input {
    color: variables.$sea-40;
    cursor: default;
    @include variables.text-16-medium;
    height: 46px;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .calendar_icon {
    color: variables.$sea-80;
    cursor: default;
  }

  .time_container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .wrapper {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      align-items: center;

      .description {
        @include variables.text-16-medium
      }

      .content {
        display: flex;
        align-items: center;
        column-gap: 8px;
        width: 168px;

        .dropdown {
          height: 46px !important;

          .dropdown_value {
            @include variables.text-16-medium;
            padding-block: 22px;
            padding-left: 16px;
          }
        }
      }

      .lock_time_entry {
        width: 168px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        svg {
          color: variables.$sea-10;
          @include variables.text-18-normal;
        }

        .switch {
          scale: 1.35;
        }

        .active {
          color: variables.$sea-60;
        }
      }
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end;
  }

  .work_time {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .line {
    @include variables.horizontal-line;
  }
}
