@use "../../style/colors";
@use "../../style/mixins";

.switch {
  width: 40px;
  height: 24px;
  position: relative;
  border-radius: 24px;
  background-color: colors.$sea-40;
  cursor: pointer;

  &.on {
    .circle {
      right: 4px;
    }
  }

  &.off {

    .circle {
      left: 4px;
    }
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: colors.$white;
    @include mixins.switch-shadow

  }
}
