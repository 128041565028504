@use "/src/style/colors";
@use "/src/style/mixins";

.pagination_wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination_container {
    display: flex;

    .pagination_icon {
      margin-inline: 1px;
      cursor: pointer;
      color: colors.$sea-10;

      &.disabled {
        cursor: default;
        color: colors.$sea-60;
      }
    }

    .text_container {
      height: fit-content;
      color: colors.$sea-60;
      @include mixins.text-16-normal();
      margin-inline: 11px;
      padding-top: 1px;
      align-self: center;
    }
  }
}
