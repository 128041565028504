@use "src/style/mixins";
@use "src/style/variables";
@use "src/style/colors";

.page_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: variables.$sea-100;
  width: 100%;
  height: 100%;
  padding: 24px 16px;

  .page_content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 30px;
    height: 100%;
    width: 100%;
    max-width: 544px;

    &.hidden {
      .title, .line, .inputs_box, .buttons {
        opacity: 0;
      }
    }

    @media(min-width: variables.$mobile) {
      justify-content: center;
    }
  }

  .logo_container {
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 241px;
    height: 46px;
  }

  .form_container {
    width: 100%;
    padding: 32px 12px;
    background-color: variables.$white;
    border-radius: 8px;
    position: relative;
    box-shadow: 6px 6px 0 0 #F9D500;
    flex: 1 0;

    @media(min-width: variables.$mobile) {
      padding: 60px 120px;
      max-height: 514px;
    }

    .title {
      @include mixins.text-18-semi-bold;
      align-self: stretch;
      margin-bottom: 49px;

      .line {
        @include mixins.horizontal-line;
      }
    }

    .inputs_box {
      display: flex;
      flex-direction: column;
      row-gap: 13px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;

    .button {
      &:nth-child(1) {
        background-color: colors.$white
      }
    }
  }
}
