@use "src/style/variables";

.secondary_button {
  background-color: variables.$gray-10;
  padding-block: 9px;
  outline: none;
  user-select: none;
  padding: 5px;
  display: inline-block;
  text-align: center;
  color: variables.$sea-100;
  margin: 0;
  transition: all 0.05s ease-in-out;
  flex: 1;
  border-radius: 8px;
  @include variables.inner-border;
  @include variables.text-18-semi-bold;

  &.active {
    color: variables.$white;
    background-color: variables.$sea-60;
    @include variables.shadow-inner;
  }

  &:disabled,
  &[aria-disabled="true"] {
    background-color: variables.$light-30;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }
}
